<template>
  <div v-if="internalValue != '' && internalValue.length > 0">
    <v-chip v-bind="$attrs" v-on="$listeners" :color="chipColor" :small="internalSmall" :dark="dark">{{ internalValue }}</v-chip>
  </div>
</template>

<script>
export default {
  inheritAttrs: false, // Supaya $attrs tidak diteruskan otomatis ke root element
  props: {
    value: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      internalValue: this.value,
      internalColor: this.color,
      internalSmall: this.small,
      internalDark: this.dark,
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.internalValue = val

        if (this.value == 'open') {
          this.internalColor = 'grey'
        }
        if (this.value == 'waiting-approval') {
          this.internalColor = 'blue'
        }
        if (this.value == 'approved') {
          this.internalColor = 'green'
        }
        if (this.value == 'closed') {
          this.internalColor = 'orange'
        }

        if (this.value == 'received') {
          this.internalColor = 'blue'
        }
        if (this.value == 'on-progress' || this.value == 'in-progress' || this.value == 'on-process') {
          this.internalColor = 'blue'
        }
        if (this.value == 'waiting-sparepart') {
          this.internalColor = 'warning'
        }
        if (this.value == 'pending') {
          this.internalColor = 'orange'
        }
        if (this.value == 'done') {
          this.internalColor = 'green'
        }
        if (this.value == 'verified') {
          this.internalColor = 'green'
        }

      } else {
        this.internalColor = 'grey'
        this.internalValue = '-'
      }
    },
    internalValue(val) {
      this.$emit('input', val);
    },
    small(val) {
      if (val) {
        this.internalSmall = val
      }
    },
    color(val) {
      if (val) {
        this.internalColor = val
      }
    }
  },
  computed: {
    chipColor(){
      if (this.color != ""){
        return this.color;
      }

      if (this.value == 'open') {
        return 'grey'
      }
      if (this.value == 'waiting-approval') {
        return 'blue'
      }
      if (this.value == 'approved') {
        return 'green'
      }
      if (this.value == 'rejected') {
        return 'red'
      }
      if (this.value == 'closed') {
        return 'orange'
      }

      if (this.value == 'received') {
        return 'blue'
      }
      if (this.value == 'on-progress' || this.value == 'in-progress' || this.value == 'on-process') {
        return 'blue'
      }
      if (this.value == 'waiting-sparepart') {
        return 'warning'
      }
      if (this.value == 'pending') {
        return 'orange'
      }
      if (this.value == 'done') {
        return 'green'
      }
      if (this.value == 'verified') {
        return 'green'
      }

      return this.internalColor;
    }
  }
}
</script>